.wrapper {
  max-width: 400px;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 90px;
  right: 60px;
  background: #fff;
  padding: 12px;

  .search-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;

    @media (max-width: 767px) {

      .css-19nvhel-MuiButtonBase-root-MuiButton-root,
      .css-la9hut-MuiButtonBase-root-MuiButton-root {
        font-size: 0.8rem;
      }
    }

    @media (max-width: 575px) {

      .css-19nvhel-MuiButtonBase-root-MuiButton-root,
      .css-la9hut-MuiButtonBase-root-MuiButton-root {
        font-size: 0.7rem;
      }
    }

    .soourceIconWrap {
      position: relative;

      .panoramaFishIcon {
        color: rgb(105, 105, 105);
      }

      .moreVertIconWrap1 {
        top: -24px;
        display: block;
      }

      .moreVertIconWrap {
        height: 20px;
        position: absolute;

        .moreVertIcon {
          color: rgb(105, 105, 105);
        }
      }
    }

    .google-autocomplete {
      width: 100%;
      border-radius: 5px;
      padding-left: 0.5rem;
      font-size: 14px;

      @media (max-width: 575px) {
        font-size: 12px;
      }

      .css-13cymwt-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #cccccc;
      }
    }
  }

  .trip-planner-btn,
  .list-view-btn {
    font-size: 14px !important;
    font-family: 'Proxima Nova', sans-serif;
    text-transform: unset;
    span {
      font-size: 12px !important;
    }

    @media (max-width: 575px) {
      font-size: 11px !important;

      span {
        font-size: 11px !important;
      }
    }
  }

  .build-trip-btn {
    font-size: 12px !important;

    @media (max-width: 575px) {
      font-size: 11px !important;
    }
  }

  .rotuesDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #ccc;
    margin-top: 5px;
    padding-top: 5px;

    .duration,
    .distance {
      font-size: 12px;
      margin-bottom: 0;
      font-family: 'Proxima Nova', sans-serif;

      @media (max-width: 575px) {
        font-size: 10px;
      }
    }
  }

  .routes-step-container {
    p {
      font-size: 14px;
      font-family: 'Proxima Nova', sans-serif;
    }

    svg {
      font-size: 26px;
    }

    @media (max-width: 991px) {
      p {
        font-size: 12px;
      }

      svg {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 1499) {
    max-width: 370px;
  }

  @media (max-width: 991px) {
    z-index: 1052;
    max-height: 370px;
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    top: 40%;
    right: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 425px) {
    max-width: 340px;
  }
}

#outlined-basic {
  background-color: rgb(58, 59, 57, 0.3);
  padding-bottom: 5px;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
  position: absolute;
  right: 40px;
  top: 110px;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.locationLabel {
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #272626;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}