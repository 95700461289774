.zoomBtnWrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .zoomInBtn {
        border-bottom: 2px solid rgb(230, 230, 230);
        border-radius: 0;

        svg {
            font-size: 14px;
        }
    }

    .zoomOutBtn {
        border-radius: 0;

        svg {
            font-size: 14px;
        }
    }
}