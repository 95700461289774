.search-option-main {
  position: absolute;
  z-index: 1050;
  top: 10px;
  left: 55px;
  padding: 16px;
  width: 250px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 77vh;
  overflow-y: auto;

  @media (max-width: 1499px) {
    max-height: 73vh;
  }

  @media (max-width: 767px) {
    left: 10px;
  }

  @media (max-width: 575px) {
    left: 50%;
    padding: 8px;
    transform: translate(-50%, 0);
  }

  .search-option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-option-text {
      text-align: left;
      color: #08652e;
      font-size: 15px;
      font-weight: 700;
      @media (max-width: 1499px) {
        font-size: 14px;
      }
    }

    button {
      padding: 0;
    }
  }

  fieldset {
    width: 100%;
    margin-top: 10px;

    p {
      margin: 0px;
      text-align: left;
      color: #585458;
      font-size: 14px;
      font-weight: 500;
      text-transform: unset;

      @media (max-width: 1499px) {
        font-size: 12px;
      }
    }

    legend {
      text-align: left;
      color: #086e38 !important;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 4px;

      @media (max-width: 1499px) {
        font-size: 12px;
      }
    }

    label {
      font-size: 14px;
      color: #585458;
      display: flex;
      align-items: center;
      line-height: 2.0375em;
      font-family: "Proxima Sera',serif";

      &.Mui-focused {
        line-height: 3.3375em;
        color: #585458;
      }

      @media (max-width: 1499px) {
        font-size: 12px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0;

        @media (max-width: 1499px) {
          font-size: 12px;
        }

        svg {
          font-size: 18px;

          path {
            fill: #08652e !important;
          }
        }
      }
    }

    .payment_types {
      margin-bottom: 0;
      line-height: 1rem;
    }

    .payment-select {
      fieldset {
        width: auto !important;
      }

      .payment-selector-input {
        .MuiSelect-multiple {
          padding-bottom: 10px;
          color: #585458;
          font-size: 14px;
          font-family: 'Proxima Sera', sans-serif;
          @media (max-width: 1499px) {
            font-size: 12px;
          }
        }
      }

      #demo-multiple-checkbox-lebal {
        color: #08652e !important;
      }

      .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        color: #08652e !important;
        line-height: 3.3375em !important;
      }

      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: #08652e;
      }
    }
  }

  .search-option-btn {
    display: flex;
    justify-content: space-between;
    margin: 12px 0 0;
    gap: 20px;

    button {
      font-family: 'Proxima Nova', sans-serif;
      background: #eea729;
      width: 50%;
      border: none;
      color: #fff;
      border-radius: 40px;
      box-shadow: none;
      font-size: 14px;
      font-weight: 700;
      padding: 6px 0;
      text-transform: unset;
    }
  }
}

.print-routes-main {
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 55px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  font-weight: bold;
  color: #08652e;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #08652e !important;
}
