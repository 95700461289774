.station-detail-selected {
  width: 150px;

  h6 {
    font-size: 14px;
    margin: 0px 0px 10px 0px;
  }

  button {
    background: #eea729;
    width: 100%;
    border: none;
    color: #fff;
    border-radius: 40px;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    height: 32px;

    &:hover {
      background: #f49e0a;
    }
  }
}
