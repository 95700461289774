.header {
  background-color: #086e38 !important;
  min-height: 5rem !important;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    min-height: 4rem !important;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 0;

    .header-logo {
      padding-left: 20px;
      width: 140px;
      height: 35px;
    }

    .renewable {
      color: #fff;
      font-weight: bold;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      padding-left: 10px;

      span {
        color: #95bd27;
      }
    }

    .iconButton {
      display: flex;
      align-items: center;
      border-right: 1px solid #95bd27;
      border-radius: 0px;
      padding: 0px 30px 0px 0px;
      gap: 10px;

      .iconText {
        color: white;
        font-weight: bold;
        line-height: 40px;
        font-size: 14px;
      }

      svg {
        font-size: 1.3rem;
      }

      .tripPlannerIconWrap {
        border: 2px solid #fff;
        border-radius: 50%;
        padding: 2.5px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 12px;
        }
      }

      &:hover {
        background-color: none !important;
      }
    }

    .mapIconBtn {
      border-right: 1px solid #95bd27;
      border-radius: 0;
      padding-right: 20px;
    }

    .findNearStation {
      padding-left: 30px;
    }

    .mapViewIcon {
      padding: 8px;
      border: 0;
      margin-right: 25px;
    }

    .grow {
      flex-grow: 1;
    }

    .search {
      display: flex;
      align-items: center;
      width: 300px;
      border: 1px solid #fff;
      border-radius: 12px;
      justify-content: space-between;

      .inputRoot {
        color: #fff;
        width: 100%;
      }

      .searchBarIcon {
        svg {
          font-size: 1.2rem;
        }
      }

      .headerLocationSearch {
        font-size: 14px;
        z-index: 1000;
      }

      .inputInput {
        padding-left: 8px;
      }

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }

      .css-1jqq78o-placeholder {
        color: #fff !important;
      }

      .css-b62m3t-container {
        width: 75%;
        text-align: left;
        background: none;
        color: #000;

        .css-13cymwt-control {
          background: none;
          border: none;
          color: #fff;
          outline: none;
        }

        #react-select-3-listbox {
          z-index: 9999999;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          color: #000 !important;

          ul {
            li {
              color: #000 !important;
            }
          }
        }
      }

      .css-166bipr-input {
        color: #fff !important;
      }

      .css-1xc3v61-indicatorContainer {
        display: none;
      }

      .css-1dimb5e-singleValue,
      .css-qbdosj-Input,
      .css-1xc3v61-indicatorContainer,
      .css-15lsz6c-indicatorContainer,
      .css-o9ehiq-loadingIndicator {
        color: #fff !important;
      }

      .css-t3ipsp-control {
        border-color: transparent !important;
        background: transparent !important;
        box-shadow: none !important;
        border: none !important;
        color: #fff !important;

        input {
          color: #fff !important;
        }

        &:hover {
          border-color: transparent !important;
          background: transparent !important;
        }
      }
    }

    @media (max-width: 1399px) {
      .renewable {
        font-size: 15px;
      }

      .iconButton {
        padding-right: 12px;
      }

      .search {
        width: 270px;

        .headerLocationSearch {
          font-size: 13px;
          z-index: 1000;
        }
      }
    }
  }
}

ul {
  li {
    color: #000 !important;
  }
}

.mobileHeader {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .mobileRenewableText {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    padding-left: 10px;
    span {
      color: #95bd27;
    }
  }

  .logo {
    padding-left: 10px;
    width: 120px;
    height: 35px;
  }

  @media (max-width: 767px) {
    .mobileRenewableText {
      font-size: 16px;
    }
  }

  @media (max-width: 420px) {
    .mobileRenewableText {
      max-width: 35%;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.drawerContainer {
  width: 300px;
  height: 100%;
  padding: 20px;
  background-color: #08652e !important;
}

.drawerContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .iconButton {
    display: flex;
    align-items: center;
    border-radius: 0px;
    padding: 0px 30px 0px 0px;
    gap: 10px;

    svg {
      color: white;
    }

    .iconText {
      color: white;
      font-weight: bold;
      line-height: 40px;
      font-size: 14px;
    }

    .tripPlannerIconWrap {
      border: 2px solid #fff;
      border-radius: 50%;
      padding: 2.5px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 12px;
      }
    }

    &:hover {
      background-color: none !important;
    }
  }

  .mapViewIcon {
    padding: 8px;
    border: 0;
    margin-right: 25px;
  }

  .grow {
    flex-grow: 1;
  }

  .search {
    display: flex;
    align-items: center;
    border: 1.5px solid #fff;
    border-radius: 12px;
    justify-content: space-between;
    width: 100%;
    font-size: 0.85rem;

    .inputRoot {
      color: #fff;
      width: 100%;
    }

    .searchBarIcon {
      font-size: 1.2rem;
      color: #fff;
    }

    .headerLocationSearch {
      z-index: 1000;
    }

    .inputInput {
      padding-left: 8px;
    }

    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }

    .css-1jqq78o-placeholder {
      color: #fff !important;
    }

    .css-b62m3t-container {
      width: 75%;
      text-align: left;
      background: none;
      color: #000;

      .css-13cymwt-control {
        background: none;
        border: none;
        color: #fff;
        outline: none;
      }

      #react-select-3-listbox {
        z-index: 9999999;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        color: #000 !important;

        ul {
          li {
            color: #000 !important;
          }
        }
      }
    }

    .css-166bipr-input {
      color: #fff !important;
    }

    .css-1dimb5e-singleValue,
    .css-qbdosj-Input,
    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer,
    .css-o9ehiq-loadingIndicator {
      color: #fff !important;
    }

    .css-t3ipsp-control {
      border-color: transparent !important;
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;
      color: #fff !important;

      input {
        color: #fff !important;
      }

      &:hover {
        border-color: transparent !important;
        background: transparent !important;
      }
    }
  }
}
