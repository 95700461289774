/* **********Proxima Nova**********  */

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Black.woff2') format('woff2'),
    url('./assets/fonts/ProximaNova-Black.woff') format('woff'),
    url('./assets/fonts/ProximaNova-Black.ttf') format('truetype'),
    url('./assets/fonts/proximanova_black.otf') format('OpenType');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('./assets/fonts/ProximaNova-Bold.woff') format('woff'),
    url('./assets/fonts/ProximaNova-Bold.ttf') format('truetype'),
    url('./assets/fonts/proximanova_bold.otf') format('OpenType');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Light.woff2') format('woff2'),
    url('./assets/fonts/ProximaNova-Light.woff') format('woff'),
    url('./assets/fonts/ProximaNova-Light.ttf') format('truetype'),
    url('./assets/fonts/proximanova_light.otf') format('OpenType');

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/ProximaNova-ExtraBold.woff') format('woff'),
    url('./assets/fonts/ProximaNova-ExtraBold.ttf') format('truetype'),
    url('./assets/fonts/proximanova_extrabold.otf') format('OpenType');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('./assets/fonts/ProximaNova-Regular.woff') format('woff'),
    url('./assets/fonts/proximanova_regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* **********Proxima Nova**********  */


/* **********Proxima Sera**********  */

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Black.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Black.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Black.otf') format('OpenType'),
    url('./assets/fonts/ProximaSera-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Bold.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Bold.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Extrabld.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Extrabld.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Extrabld.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Extrlgt.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Extrlgt.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Extrlgt.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Light.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Light.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Medium.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Medium.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Regular.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Regular.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Thin.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Thin.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Sera';
  src: url('./assets/fonts/ProximaSera-Semibold.woff2') format('woff2'),
    url('./assets/fonts/ProximaSera-Semibold.woff') format('woff'),
    url('./assets/fonts/ProximaSera-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* **********Proxima Sera**********  */


body {
  margin: 0;
  padding: 0 !important;
  font-family: 'Proxima Nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cky-box-bottom-left {
  bottom: 80px !important;
  @media (max-width: 576px) {
    bottom: 95px !important;
  }
}

.cky-consent-bar .cky-notice-des p,
.cky-gpc-wrapper .cky-gpc-desc p,
.cky-preference-body-wrapper .cky-preference-content-wrapper p,
.cky-accordion-header-wrapper .cky-accordion-header-des p,
.cky-cookie-des-table li div:last-child p {
  font-family: 'Proxima Sera', sans-serif !important;
}


.cky-notice .cky-title {
  font-family: 'Proxima Nova', sans-serif !important;
}

.cky-revisit-bottom-left {
  bottom: 90px !important;
}

.cky-btn {
  line-height: 18px !important;
}