.cookie-disclaimer {
  position: fixed;
  bottom: 62px;
  left: 0;
  right: 0;
  background: #333;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 767px) {
    bottom: 85px;
  }
}

.cookie-disclaimer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.cookie-disclaimer p {
  margin: 0;
  padding-right: 10px;
  font-size: 14px;
  font-family: 'Proxima Sera', sans-serif;
}

.cookie-disclaimer button {
  background: #f1c40f;
  border: none;
  padding: 8px 16px;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
}