.station-details-section {
  position: absolute;
  z-index: 999;
  top: 95px;
  right: 10px;
  max-width: 450px;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 82vh;
  overflow-y: auto;

  @media (max-width: 1499px) {
    top: 90px;
    max-height: 76vh;
  }

  @media (max-width: 575px) {
    right: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    max-width: 350px;
  }

  .station-details-img {
    height: 250px;
    width: 100%;

    @media (max-width: 1499px) {
      height: 190px;
    }

    img {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 575px) {
      height: 150px;
    }
  }

  .station-details-close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .station-details {
    padding: 24px;

    @media (max-width: 1499px) {
      padding: 18px;
    }

    @media (max-width: 767px) {
      padding: 16px;
    }

    h2 {
      text-align: left;
      color: #08652e;
      font-size: 22px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 10px;

      @media (max-width: 1499px) {
        font-size: 18px;
        margin-bottom: 8px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    h3,
    p {
      margin: 0px;
      text-align: left;
      color: #585458;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 22px;

      span {
        font-family: 'Proxima Sera', sans-serif;
        margin-left: 5px;
      }

      @media (max-width: 1499px) {
        font-size: 12px;
        margin-bottom: 6px;
        line-height: 18px;
      }
    }

    .station-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0px 0px 0px;

      .station-status {
        width: 50%;
        // margin-bottom: 10px;

        h4 {
          text-align: left;
          color: #08652e;
          font-size: 14px;
          font-weight: 700;

          @media (max-width: 1499px) {
            font-size: 12px;
          }
        }

        h5 {
          margin: 0px;
          text-align: left;
          color: #585458;
          font-size: 14px;

          @media (max-width: 1499px) {
            font-size: 12px;
          }
        }
      }
    }

    .hourlyOpenAndAccepts {
      text-align: left;
      color: #08652e;
      font-size: 16px;
      font-weight: 700;

      span {
        margin: 0px;
        color: #585458;
        font-weight: 400;
        letter-spacing: 0.01071em;
      }

      @media (max-width: 1499px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .btn-station {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0 0;
      gap: 10px;

      @media (min-width: 576px) {
        justify-content: space-between;
        gap: 20px;
      }

      button {
        font-family: 'Proxima Nova', sans-serif;
        background: #eea729;
        border: none;
        color: #fff;
        border-radius: 40px;
        box-shadow: none;
        font-size: 14px;
        font-weight: 700;
        text-transform: inherit;
        height: 36px;
        max-width: 120px;
        width: 100%;

        @media (max-width: 575px) {
          font-size: 12px;
          height: 30px;
          max-width: 110px;
        }
      }
    }
  }
}

.share-menu {
  li {
    color: #585458 !important;

    svg {
      margin-right: 10px;
    }
  }
}

.contact-modal {
  .contact-modal-body {
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;

    @media (max-width: 575px) {
      max-width: 300px;
    }

    @media (max-width: 425px) {
      max-width: 250px;
    }

    h3 {
      color: #08652e;
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }

    .contact-input {
      width: 100%;
      margin: 10px 0px 10px;
    }

    .contact-footer {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .direction-btn {
        color: #08652e;
        font-size: 13px;
        font-weight: 700;
        text-align: left;
      }

      .contact-footer-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        button {
          width: 100%;
          border: none;
          border-radius: 40px;
          box-shadow: none;
          font-size: 14px;
          height: 36px;
          padding: 0px 24px !important;
          font-weight: 700;
          cursor: pointer;

          &:hover {
            background-color: #eea729 !important;
          }

          &:disabled {
            background-color: #efcb8d !important;
          }
        }

        .emailSendButton {
          background-color: #eea729 !important;
          color: #fff !important;
        }

        .cancelButton {
          font-weight: 500;
          background-color: transparent;
          color: rgb(69, 69, 69);
          border: 3px solid #eea729;
        }
      }
    }
  }
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  margin: 3px 0px 0px 0px;
}
