.App {
  position: relative;
}
.map-section {
  height: calc(100vh - 80px);
  position: relative;
  @media (max-width: 991.9px) {
    height: calc(100vh - 63px);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.leaflet-marker-draggable {
  z-index: 1500 !important;
}

.leaflet-routing-container.leaflet-bar {
  display: none !important;
}

.leaflet-routing-remove-waypoint:after {
  right: -152px !important;
  top: -35.5px !important;
  height: 6px !important;
  margin-top: 0 !important;
  padding-right: 4px !important;
  background: #fff !important;
}

.leaflet-routing-geocoder {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
