.footer-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 1001;
    width: 100%;
    background-color: #086e38;

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 16px;

        @media (max-width:767px) {
            gap: 10px;
        }

        @media (max-width: 575px) {
            padding: 16px;
        }
    }

    .footer-logo {
        width: 100px;
        height: 30px;

        @media (max-width: 575px) {
            height: 25px;
        }
    }

    .footer-text {
        font-size: 14px;
        color: #fff;
        font-weight: 700;

        @media (max-width:767px) and (min-width: 359px) {
            font-size: 12px;

            &.text-hotline-service {
                flex: 0 0 100px;
            }

            &.text-copywrite {
                flex: 0 0 100px;
            }
        }
    }
}