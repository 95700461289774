.frontsheet,
.backsheet {
  background: #FFF;
  border-color: #AFB1B6;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0), 0 0 50px rgba(0, 0, 0, 0.05) inset;
  box-sizing: border-box;
  color: #313740;
  min-height: 800px;
  position: relative;
  width: 1060px;
}

.backsheet {
  margin: 0 auto;

  &::before {
    content: "";
    display: block;
    height: 79px;
    left: 20px;
    position: absolute;
    top: -13px;
    width: 71px;
    z-index: 9;
  }
}

.frontsheet {
  margin: -7px -7px 5px;
}

.print-container {
  padding: 10px;
  margin: auto;
  max-width: 1000px;
  width: 100%;

  .print-button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
  }
  .leaflet-control{
    display: none !important;
  }
  /* Print-specific styles */
  @media print {
    .print-button {
      display: none;
    }
  }
}

.routesArea {
  display: flex;
  gap: 20px;
}

.routesDetailContent{
  flex: 0 0 650px;
}

.listStep {
  cursor: pointer;
  margin: 0;
  padding: 15px 0 23px;
  position: relative;

  .listStepInstructrion {
    display: block;
    padding-left: 10%;
    font-size: 14px;
    font-weight: 700;
  }

  .direction-separator {
    bottom: -4px;
    display: block;
    height: 21px;
    position: absolute;
    width: 100%;
  }

  .directions-line {
    border-bottom: 1px solid #E6E6E6;
    display: block;
    padding-top: 20px;
  }

  .directions-distance-time {
    color: #777;
    position: absolute;
    right: 0;
    text-align: right;
    top: 8px;
    z-index: 1;
    font-size: 14px;
    font-weight: 600;

    .listStepDistance {
      background: #FFF;
      padding-left: 5px;
    }
  }
}

.estimation{
  span{
    font-weight: 700;
  }
}